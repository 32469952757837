import logo from './logo.svg';
import logo2 from './logo2.svg';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <h1>Arsel Soluções</h1>
        
        <center>
          <img src={logo2} alt="logo" />
          <p>
            Em breve!
          </p>
        </center>
        
      </header>
    </div>
  );
}

export default App;
